<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { getDataObjectById } from 'o365-dataobject';

const props = defineProps({
    itemID: Number,
    taskRow: Object,
    sjaID: Number,
    readOnly: Boolean,
    participants: Boolean
});

let dsHazards = getDataObjectById(`dsTasksHazards_${props.sjaID}`);

const hazardData = computed(() => {
    return dsHazards.data.filter(item => item.SJATask_ID == props.taskRow.ID);
});

let sumActions = 0;
watch(hazardData, (newHazardData) => {
    sumActions = 0;
    newHazardData.forEach((hazard, index) => {
        sumActions += hazard.Actions;
    });
}, { immediate: true });

</script>

<template>
    <div class="card px-1" style="border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 var(--bs-card-border-radius); !important">
        <span>{{ $t('Number of hazards') }}: {{ hazardData.length }}</span>
        <span>{{ $t('Number of actions') }}: {{ sumActions }}</span>
    </div>
</template>

<style scoped></style>